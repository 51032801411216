import React from 'react';

import LoaderWrapper from './LoaderWrapper';

function Loader() {
  return (
    <LoaderWrapper>
      <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <circle cx="50" cy="50" r="35" fill="none" stroke="#003262" strokeWidth="5" strokeDasharray="164.934 56.978" />
      </svg>
    </LoaderWrapper>
  );
}

export default Loader;
