import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  background-color: #fff;
  padding: 25px;
  box-shadow: 0 2px 6px 0 rgba(0, 50, 98, 0.1);
`;

export default Wrapper;
