import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

// Import CSS reset and Global Styles
import 'sanitize.css';
import GlobalStyles from './global-styles';

import App from './containers/App';
import ScrollToTop from './components/ScrollToTop';

import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop>
      <>
        <GlobalStyles />
        <App />
      </>
    </ScrollToTop>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

console.log(`version: ${process.env.REACT_APP_VERSION}, branch: ${process.env.REACT_APP_BRANCH}`); // eslint-disable-line no-console
