import styled from 'styled-components';
import { MEDIA_QUERIES, COLORS } from 'styleguide';

const Alert = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  color: ${(props) => props.success ? COLORS.midnightBlue : COLORS.maroonFlush};

  svg {
    flex: 0 0 auto;
  }

  @media (min-width: ${MEDIA_QUERIES.mobile}) {
    top: 70px;
    bottom: auto;
    right: 0;
    width: 320px;
  }
`;

export default Alert;
