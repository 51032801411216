import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from './Wrapper';
import PositionMsg from './PositionMsg';
import Alert from './Alert';

class AlertMsg extends React.PureComponent {
  render() {
    const success = this.props.classNames.indexOf('s-alert-success') !== -1;

    return (
      <Alert id={this.props.id} style={this.props.styles} success={success}>
        <Wrapper>
          {success
            ? (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" width="25" height="25">
                <g fillRule="evenodd">
                  <path d="M0 7a7 7 0 1 1 14 0A7 7 0 0 1 0 7z" fill="currentColor" />
                  <path d="M13 7A6 6 0 1 0 1 7a6 6 0 0 0 12 0z" fill="#FFF" />
                  <path d="M6.278 7.697L5.045 6.464a.296.296 0 0 0-.42-.002l-.613.614a.298.298 0 0 0 .002.42l1.91 1.909a.5.5 0 0 0 .703.005l.265-.265L9.997 6.04a.291.291 0 0 0-.009-.408l-.614-.614a.29.29 0 0 0-.408-.009L6.278 7.697z" fill="currentColor" />
                </g>
              </svg>
            )
            : (
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 28 28">
                <path fill="#A41F52" fillRule="nonzero" d="M14 28C6.268 28 0 21.732 0 14S6.268 0 14 0s14 6.268 14 14-6.268 14-14 14zm0-2c6.627 0 12-5.373 12-12S20.627 2 14 2 2 7.373 2 14s5.373 12 12 12zm1.617-10.031H13.04l-.398-7.39h3.375l-.399 7.39zm-2.945 2.734c0-.474.14-.842.422-1.105.281-.263.69-.395 1.226-.395.532 0 .934.132 1.207.395.274.263.41.631.41 1.105 0 .469-.141.835-.425 1.098-.284.263-.681.394-1.192.394-.515 0-.919-.131-1.21-.394-.292-.263-.438-.63-.438-1.098z" />
              </svg>
            )}

          <PositionMsg>
            {this.props.message}
          </PositionMsg>
        </Wrapper>
      </Alert>
    );
  }
}

AlertMsg.propTypes = {
  id: PropTypes.string.isRequired,
  styles: PropTypes.object,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  classNames: PropTypes.string.isRequired,
};

AlertMsg.defaultProps = {
  styles: {},
};

export default AlertMsg;
