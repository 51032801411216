import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
`;

export const LoaderWrapper = styled.div`
  margin: 0 auto;
  padding: 25px 0;
  text-align: center;

  svg {
    animation: ${spin} 2s infinite linear;
  }
`;

export default LoaderWrapper;
