import { css } from 'styled-components';
import { rgba } from 'polished';

export const COLORS = {
  midnightBlue: '#004180',
  maroonFlush: '#a41f52',
  indigo: '#4887c7',
  aquaHaze: '#f7f9fa',
  alto: '#d4d4d4',
};

export const MEDIA_QUERIES = {
  mobile: '660px',
  tablet: '914px',
  laptop: '1400px',
};

export const TYPOGRAPHY = {
  headingMedium: css`
    font-family: 'Open Sans', sans-serif;
    font-size: 30px;
    font-weight: 700;

    @media (min-width: ${MEDIA_QUERIES.laptop}) {
      font-size: 46px;
    }
  `,
  headingSmall: css`
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 600;

    @media (min-width: ${MEDIA_QUERIES.mobile}) {
      font-size: 24px;
    }
  `,
  body: css`
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: normal;
  `,
  bodySemibold: css`
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 600;
  `,
  bodySmall: css`
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: normal;
  `,
  bodySmallSemibold: css`
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
  `,
  adminInputSemibold: css`
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0.3px;
  `,
  adminTextarea: css`
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    line-height: 36px;
    letter-spacing: 0.3px;
  `,
  pincode: css`
    font-family: 'Open Sans', sans-serif;
    font-size: 30px;

    @media (min-width: ${MEDIA_QUERIES.mobile}) {
      font-size: 46px;
    }
  `,
};

export const BOX_SHADOWS = {
  card: css`
    box-shadow: 0 15px 22px -10px ${rgba(COLORS.midnightBlue, 0.1)};
  `,
  button: css`
    box-shadow: 0 0 0 1px ${COLORS.indigo};
  `,
  login: css`
    box-shadow: -12px 0 26px 0 ${rgba(COLORS.midnightBlue, 0.1)};
  `,
  list: css`
    box-shadow: 0 2px 6px 0 ${rgba(COLORS.midnightBlue, 0.1)};
  `,
};

export const Z_INDEX = {
  rightMenu: 2,
};
