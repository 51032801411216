import { createGlobalStyle } from 'styled-components';
import { lighten } from 'polished';

import OpenSansRegular from './fonts/open-sans-regular.woff';
import OpenSansRegular2 from './fonts/open-sans-regular.woff2';
import OpenSansSemiBold from './fonts/open-sans-semibold.woff';
import OpenSansSemiBold2 from './fonts/open-sans-semibold.woff2';
import OpenSansBold from './fonts/open-sans-bold.woff';
import OpenSansBold2 from './fonts/open-sans-bold.woff2';
import { COLORS } from './styleguide';

/* eslint no-unused-expressions: 0 */
export const GlobalStyle = createGlobalStyle`
  .body_white {
    background-color: #fff;
  }

  /* for import html templates (header/footer) */
  .consents-form__text a {
    color: ${COLORS.indigo};
    text-decoration: none;

    &:hover,
    &:active {
      text-decoration: underline;
      color: ${lighten(0.1, COLORS.indigo)};
    }
  }

  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src:
      local('Open Sans Regular'),
      local('OpenSans-Regular'),
      url('${OpenSansRegular}') format('woff'),
      url('${OpenSansRegular2}') format('woff2');
  }

  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src:
      local('Open Sans SemiBold'),
      local('OpenSans-SemiBold'),
      url('${OpenSansSemiBold}') format('woff'),
      url('${OpenSansSemiBold2}') format('woff2');
  }

  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src:
      local('Open Sans Bold'),
      local('OpenSans-Bold'),
      url('${OpenSansBold}') format('woff'),
      url('${OpenSansBold2}') format('woff2');
  }

  body {
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 16px;
    color: ${COLORS.midnightBlue};
    background-color: ${COLORS.aquaHaze};
  }

  #root {
    min-height: 100%;
  }
`;

export default GlobalStyle;
