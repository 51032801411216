import styled from 'styled-components';
import { TYPOGRAPHY } from 'styleguide';

const PositionMsg = styled.div`
  margin-left: 19px;
  line-height: 26px;
  ${TYPOGRAPHY.bodySemibold}
`;

export default PositionMsg;
