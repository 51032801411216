import React from 'react';
import Alert from 'react-s-alert';
import { Switch, Route, withRouter } from 'react-router-dom';

import AppUser from 'containers/AppUser/Loadable';
import AppAdmin from 'containers/AppAdmin/Loadable';
import AlertMsg from 'components/AlertMsg';

export class App extends React.PureComponent {
  componentDidMount() {
    this.deleteCaches();
  }

  componentWillUnmount() {
    window.sessionStorage.removeItem('visitedNewConsents');
  }

  deleteCaches = async () => {
    // TODO: удалить после релиза, удаление кеша SW
    try {
      caches.keys().then((cachesName) => {
        cachesName.forEach((item) => caches.delete(item));
      });
    } catch (err) {} // eslint-disable-line no-empty
  }

  render() {
    return (
      <>
        <Switch>
          <Route path="/consentmanagement" component={AppAdmin} />
          <Route component={AppUser} />
        </Switch>

        <Alert
          effect="bouncyflip"
          contentTemplate={AlertMsg}
          offset={0}
          timeout={1500}
        />
      </>
    );
  }
}

export default withRouter(App);
